import axios from '@/axios-client.js';

const projectAPI = {

  async getProject( baseUrl, projectSlug ) {
    const response = await axios.get(
      `${baseUrl}v2/projects/${projectSlug}/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getProjectSubscription({ baseUrl, projectSlug }) {
    const response = await axios.get(
      `${baseUrl}projects/${projectSlug}/subscription/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async subscribeProject({ baseUrl, projectSlug, suscribe }) {
    const response = await axios.put(
      `${baseUrl}projects/${projectSlug}/subscription/`,
      { is_suscriber: suscribe }
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getProjects({ baseUrl, filters, page, projectSlug, myaccount, text }) {
    let url = `${baseUrl}v2/projects/`;
    if (projectSlug) {
      url += `${projectSlug}/`;
    }
    url += `?page=${page}`;
    if (myaccount) {
      url += '&myaccount=true';
    }
    // Append search text if provided.
    if (text) {
      url += `&search=${encodeURIComponent(text)}`;
    }
    try {
      if (Object.values(filters).some(el => el && el.length > 0)) {
        for (const filter in filters) {
          if (filters[filter]) {
            url = url.concat('', `&${filter}=${filters[filter]}`);
          }
        }
      }
      const response = await axios.get(url);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async getProjectUsers( baseUrl, projectSlug) {
    const response = await axios.get(
      `${baseUrl}projects/${projectSlug}/utilisateurs/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getProjectTypes( baseUrl ) {
    const response = await axios.get(
      `${baseUrl}v2/projects/?is_project_type=true`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data.results;
    } else {
      return null;
    }
  },

  async deleteProject(baseUrl, projectSlug) {
    const response = await axios.delete(
      `${baseUrl}v2/projects/${projectSlug}/`
    );
    if ( response.status === 204 ) {
      return 'success';
    } else {
      return null;
    }
  },
};

export default projectAPI;
