<template>
  <li
    :ref="'message-' + message.counter"
    :class="['list-container', { show }]"
  >
    <div :class="['list-item', { show}]">
      <div :class="['ui', message.level ? message.level : 'info', 'message']">
        <i
          class="close icon"
          aria-hidden="true"
          @click="removeListItem"
        />
        <div class="header">
          <i
            class="info circle icon"
            aria-hidden="true"
          />
          Informations
        </div>
        <ul class="list">
          {{
            message.comment
          }}
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MessageInfo',

  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      listMessages: [],
      show: false,
    };
  },

  computed: {
    ...mapState(['messages']),
  },

  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 15);
  },

  methods: {
    ...mapMutations(['DISCARD_MESSAGE']),

    removeListItem(){
      const container = this.$refs['message-' + this.message.counter];
      container.ontransitionend = () => {
        this.DISCARD_MESSAGE(this.message.counter);
      };
      this.show = false;
    },
  },
  
};
</script>

<style scoped>
.list-container{
    list-style: none;
    width: 100%;
    height: 0;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s ease-out;
}
.list-container.show{
  height: 6em;
}
.list-container.show:not(:first-child){
  margin-top: 10px;
}
.list-container .list-item{
    padding: .5rem 0;
    width: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transition: all 0.6s ease-out;
}
.list-container .list-item.show{
    opacity: 1;
}

ul.list{
  overflow: auto;
  height: 2.2em;
  margin-bottom: .5em !important;
}

.ui.message {
  overflow: hidden;
  padding-bottom: 0 !important;
}
.ui.message::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 1em;
  right: 0;
  width: calc(100% - 2em);
}
.ui.info.message::after {
  box-shadow: 0px -8px 5px 3px rgb(248, 255, 255);
}
.ui.positive.message::after {
  box-shadow: 0px -8px 5px 3px rgb(248, 255, 255);
}
.ui.negative.message::after {
  box-shadow: 0px -8px 5px 3px rgb(248, 255, 255);
}

.ui.message > .close.icon {
  cursor: pointer;
  position: absolute;
  margin: 0em;
  top: 0.78575em;
  right: 0.5em;
  opacity: 0.7;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
</style>