<template>
  <div id="search-projects">
    <input
      type="text"
      placeholder="Rechercher un projet ..."
      @input="searchProjects"
    >
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'SearchProjects',

  methods: {
    ...mapMutations('projects', [
      'SET_CURRENT_PAGE'
    ]),
    ...mapActions('projects', [
      'SEARCH_PROJECTS'
    ]),

    searchProjects:
      debounce(function(e) {
        this.$emit('loading', true);
        this.SET_CURRENT_PAGE(1);
        this.SEARCH_PROJECTS({ text: e.target.value })
          .then(() => {
            this.$emit('loading', false);
          })
          .catch((err) => {
            if (err.message) {
              this.$emit('loading', false);
            }
          });
      }, 100)
  }
};
</script>

<style lang="less" scoped>
#search-projects {
  height: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1rem;
  input {
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    color: #35495e;
    padding: 8px 40px 8px 8px;
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-family: var(--font-family, 'Roboto Condensed', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif);
  }
  input:focus {
    outline: none !important;
    box-shadow: 0 0 1px grey;
  }
}
</style>
