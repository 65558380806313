<template>
  <div
    :id="project.title"
    class="item"
    data-test="project-list-item"
  >
    <div class="ui tiny image">
      <img
        :src="
          !project.thumbnail
            ? require('@/assets/img/default.png')
            : DJANGO_BASE_URL + project.thumbnail + refreshId()
        "
        alt="Thumbnail du projet"
      >
    </div>
    <div class="middle aligned content">
      <router-link
        :to="{
          name: 'project_detail',
          params: { slug: project.slug },
        }"
        class="header"
      >
        {{ project.title }}
      </router-link>
      <div class="description">
        <textarea
          :id="`editor-${project.slug}`"
          :value="project.description"
          :data-preview="`#preview-${project.slug}`"
          hidden
        />
        <div
          :id="`preview-${project.slug}`"
          class="preview"
        />
      </div>
      <div class="meta top">
        <span class="right floated">
          <strong v-if="project.moderation">Projet modéré</strong>
          <strong v-else>Projet non modéré</strong>
        </span>
        <span>Niveau d'autorisation requis :
          {{ project.access_level_pub_feature }}</span><br>
        <span v-if="user">
          Mon niveau d'autorisation :
          <span v-if="USER_LEVEL_PROJECTS && project">{{
            USER_LEVEL_PROJECTS[project.slug]
          }}</span>
          <span v-if="user && user.is_administrator">{{
            "+ Gestionnaire métier"
          }}</span>
        </span>
      </div>
      <div class="meta">
        <span class="right floated">
          <i
            class="calendar icon"
            aria-hidden="true"
          />&nbsp; {{ project.created_on }}
        </span>
        <span data-tooltip="Membres">
          {{ project.nb_contributors }}&nbsp;
          <i
            class="user icon"
            aria-hidden="true"
          />
        </span>
        <span data-tooltip="Signalements publiés">
          {{ project.nb_published_features }}&nbsp;
          <i
            class="map marker icon"
            aria-hidden="true"
          />
        </span>
        <span data-tooltip="Commentaires">
          {{ project.nb_published_features_comments }}&nbsp;
          <i
            class="comment icon"
            aria-hidden="true"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import TextareaMarkdown from 'textarea-markdown';

import { mapState } from 'vuex';

export default {

  name: 'ProjectsListItem',

  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS'
    ]),
    DJANGO_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_BASE;
    },
  },

  mounted() {
    let textarea = document.getElementById(`editor-${this.project.slug}`);
    new TextareaMarkdown(textarea);
  },

  methods: {
    refreshId() {
      const crypto = window.crypto || window.msCrypto;
      var array = new Uint32Array(1);
      return '?ver=' + crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    },
  }
};

</script>

<style lang="less" scoped>
.preview {
  max-height: 10em;
  overflow-y: scroll;
  margin-bottom: 0.8em;
}

.description {
  p {
    text-align: justify;
  }
}

@media screen and (max-width: 767px) {
  .content {
    width: 90% !important;

    .meta.top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .right.floated {
        float: none !important;
        margin-left: 0 !important;
        margin-bottom: 0.5em;
      }

      span {
        margin: 0.15em 0;
      }
    }
  }
}

</style>
