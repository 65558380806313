
const modals = {

  namespaced: true,

  state: {
    isProjectModalOpen: false,
    projectModalType: null
  },

  mutations: {
    OPEN_PROJECT_MODAL(state, payload) {
      state.isProjectModalOpen = true;
      state.projectModalType = payload;
    },

    CLOSE_PROJECT_MODAL(state) {
      state.isProjectModalOpen = false;
      state.projectModalType = null;
    }
  },

  actions: {

  }

};

export default modals;
