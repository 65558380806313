<template>
  <div id="app-footer">
    <div class="ui compact text menu">
      <router-link
        :to="{name: 'mentions'}"
        class="item"
      >
        Mentions légales
      </router-link>
      <router-link
        :to="{name: 'aide'}"
        class="item"
      >
        Aide
      </router-link>
      <p class="item">
        Version {{ PACKAGE_VERSION }}
      </p>
    </div>
  </div>
</template>

<script>
export default {

  name: 'AppFooter',

  computed: {
    PACKAGE_VERSION: () => process.env.PACKAGE_VERSION || '0',
  }

};
</script>
