<template>
  <div id="projects">
    <h2 class="ui horizontal divider header">
      PROJETS
    </h2>

    <div class="flex">
      <router-link
        v-if="user && user.can_create_project && isOnline"
        :to="{ name: 'project_create', params: { action: 'create' } }"
        class="ui green basic button"
        data-test="create-project"
      >
        <i
          class="plus icon"
          aria-hidden="true"
        />
        Créer un nouveau projet
      </router-link>
      <router-link
        v-if="user && user.can_create_project && isOnline"
        :to="{
          name: 'project_type_list',
        }"
        class="ui blue basic button"
        data-test="to-project-models"
      >
        <i
          class="copy icon"
          aria-hidden="true"
        />
        Accéder à la liste des modèles de projets
      </router-link>
    </div>

    <!-- FILTRES DES PROJETS -->
    <ProjectsMenu
      :loading="loading"
      @filter="setProjectsFilters"
      @getData="getData"
      @loading="setLoader"
    />

    <div
      v-if="configuration.DISPLAY_FORBIDDEN_PROJECTS"
      id="forbidden-projects"
      class="ui toggle checkbox margin-top"
    >
      <input
        :checked="displayForbiddenProjects"
        type="checkbox"
        @input="toggleForbiddenProjects"
      >
      <label>
        N'afficher que les projets disponibles à la consultation
      </label>
    </div>

    <!-- LISTE DES PROJETS -->
    <div
      v-if="projects"
      class="ui divided items dimmable dimmed"
      data-test="project-list"
    >
      <div :class="['ui inverted dimmer', { active: loading }]">
        <div class="ui loader" />
      </div>
    
      <ProjectsListItem
        v-for="project in projects"
        :key="project.slug"
        :project="project"
      />

      <span
        v-if="!projects || projects.length === 0"
      >
        Vous n'avez accès à aucun projet.
      </span>

      <!-- PAGINATION -->
      <Pagination
        v-if="count"
        :nb-pages="nbPages"
        @page-update="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import ProjectsMenu from '@/components/Projects/ProjectsMenu';
import ProjectsListItem from '@/components/Projects/ProjectsListItem';
import Pagination from '@/components/Pagination';

export default {
  name: 'ProjectsList',

  components: {
    ProjectsMenu,
    ProjectsListItem,
    Pagination
  },
  
  data() {
    return {
      loading: false,
      displayForbiddenProjects: false
    };
  },

  computed: {
    ...mapState([
      'configuration',
      'user',
      'isOnline',
    ]),
    ...mapState('projects', [
      'projects',
      'count',
      'filters',
    ]),
    DJANGO_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_BASE;
    },
    nbPages() {
      return Math.ceil(this.count / 10);
    }
  },

  created() {
    this.SET_CURRENT_PAGE(1);
    // Empty stored text to search
    this.SET_PROJECTS_SEARCH_STATE({ text: null });
    // Empty stored project list
    this.$store.commit('projects/SET_PROJECT', null);
    // Init display of restricted access projects
    this.displayForbiddenProjects = this.configuration.DISPLAY_FORBIDDEN_PROJECTS_DEFAULT;
    this.setForbiddenProjectsFilter(true);
  },

  methods: {
    ...mapMutations('projects', [
      'SET_CURRENT_PAGE',
      'SET_PROJECTS_FILTER',
      'SET_PROJECTS_SEARCH_STATE',
    ]),
    ...mapActions('projects', [
      'GET_PROJECTS',
    ]),

    getData(page) {
      this.loading = true;
      this.GET_PROJECTS({ page })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setLoader(e) {
      this.loading = e;
    },

    changePage(e) {
      this.getData(e);
    },

    setProjectsFilters(e, noUpdate) {
      this.SET_PROJECTS_FILTER(e);
      // Reset the page number at filter change
      this.SET_CURRENT_PAGE(1);
      // Wait that all filters are set in store to fetch data when component is created
      if (!noUpdate) {
        this.getData();
      }
    },

    toggleForbiddenProjects(e) {
      this.displayForbiddenProjects = e.target.checked;
      this.setForbiddenProjectsFilter();
    },

    setForbiddenProjectsFilter(noUpdate) {
      this.setProjectsFilters({
        filter: 'accessible',
        value: this.displayForbiddenProjects ? 'true' : null
      }, noUpdate);
    },
  }
};
</script>

<style lang="less" scoped>

#projects {
  margin: 0 auto;

  .dimmable {

    .dimmer {
      
      .loader {
        top: 25%;
      }
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

#filters-divider {
  padding-top: 0;
  color: gray !important;
}

#forbidden-projects.checkbox {
  font-size: 1.2em;
  font-weight: 600;
  label {
    color: rgb(94, 94, 94);
  }
  input:checked ~ label::before {
    background-color: var(--primary-color, #008c86) !important;
  }
  input:checked ~ label {
    color: var(--primary-color, #008c86) !important;
  }
}

</style>