import axios from 'axios';

axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use(function (config) {

  config.headers['X-CSRFToken'] = (name => {
    const re = new RegExp(name + '=([^;]+)');
    const value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');

  return config;

}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {

  response.headers['X-CSRFToken'] = (name => {
    const re = new RegExp(name + '=([^;]+)');
    const value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');

  return response;

}, function (error) {
  return Promise.reject(error);
});

export default axios;
