<template>
  <div>
    <geor-header
      :legacy-header="legacyHeader"
      :legacy-url="legacyUrl"
      :style="customStyle"
      :logo-url="logo"
      :stylesheet="customStylesheet"
      :active-app="activeApp"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {

  name: 'GeorchestraHeader',

  computed: {
    ...mapState([
      'configuration'
    ]),

    headerConfig() {
      return this.configuration.GEORCHESTRA_INTEGRATION?.HEADER;
    },
    activeApp() {
      return this.$route.path.includes('my_account') ? 'geocontrib-account' : 'geocontrib';
    },
    logo() {
      return this.configuration.VUE_APP_LOGO_PATH;
    },
    legacyHeader() {
      return this.headerConfig.LEGACY_HEADER;
    },
    legacyUrl() {
      return this.headerConfig.LEGACY_URL;
    },
    customStyle() {
      return this.headerConfig.STYLE;
    },
    customStylesheet() {
      return this.headerConfig.STYLESHEET;
    },
    headerScript() {
      return this.headerConfig.HEADER_SCRIPT;
    },
  },

  mounted() {
    const headerScript = document.createElement('script');
    headerScript.setAttribute('src', this.headerScript ? this.headerScript : 'https://cdn.jsdelivr.net/gh/georchestra/header@dist/header.js');
    document.head.appendChild(headerScript);
  },
};
</script>
